import React from 'react';
import './App.scss';

const App = () => {
  return (
    <div className="grid">
        <div className="leftHome">
            <img className="barHome" src="name.svg"/>
            <img className="barHome" src="profile.svg"/>
            <div className="ach">
                <ul className="achList">
                    <li className="b1">
                        <div className="be"> 
                            Mechanical Engineer on Monterrey Institute of Technology and Higher Education
                        </div>
                    </li>
                    <li className="b1">
                        <div className="be"> 
                            First Place Latin American Robotics Competition Open @HOME 2023
                        </div>
                    </li>
                    <li className="b1">
                        <div className="be"> 
                            Second Place National @MakeX 2019
                        </div>
                    </li>
                    <li className="b1">
                        <div className="be"> 
                            Second Place National @Robocup Soccer 2019
                        </div>
                    </li>
                    <li className="b1">
                        <div className="be"> 
                            Third Place National World Robot Olympiad 2018
                        </div>
                    </li>
                </ul>
            </div>
            <img className="titleHome" src="title.svg"/>
        </div>
        <div className="rightHome">
            <div className="profilePicContainer">
                <img className="profilePic" src="PP.png"/>
            </div>
        </div>
        
        
        <div className='project'>
            <div className='p-content'>
                <div className='p-title'>
                    Name: Ecoshell
                </div>
                <div className='p-desc'>
                    WHAT
                    <br/><br/><br/>
                    Participated in the Shell Eco-Marathon, an international competition focused on designing and manufacturing a single-seater vehicle optimized for efficiency and energy use. The Shell Eco-marathon is a prestigious global event, with editions held in the United Kingdom, France, the Netherlands, Japan, and the United States. 
                    <br/><br/><br/>
                    As a Dynamic Systems Engineer, I played a key role in designing and building the steering and braking systems.
                    <br/><br/><br/>
                    HOW
                    <br/><br/><br/>
                    Created designs in SolidWorks to meet competition specifications for wheelbase, track width, and turning radius, ensuring minimal weight.
                    Designed jigs and fixtures to enhance precision in the manufacturing of vehicle components.
                    Assisted in manufacturing processes, including lathe and milling operations, 3D printing, and welding
                    <br/><br/><br/>
                    RESULTS
                    <br/><br/><br/>
                    The car attended the Americas 2024 race, held at Indianapolis Motor Speedway, in Indiana, USA.
                    Achieved an 11 degrees steering angle and a turning radius of 7 meters.
                </div>
            </div>
            <div className='p-details'>
                <div className='p-role'>
                    Participation
                    <br/><br/><br/>
                    [November 2023 – June 2024]
                    <br/><br/><br/>
                    Ecoshell Monterrey - Shell Eco-Marathon Team
                    <br/><br/><br/>
                    Role
                    <br/><br/><br/>
                    Dynamic Systems Engineer 
                    <br/><br/><br/>
                </div>
                <div className='p-img-container'>
                    <img className='p-img' src='ecoshell.png'/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
